import classNames from "classnames";
import { graphql } from "gatsby";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Logo from "../assets/LogoWatermark.svg";
import IconChevron from "../assets/icons/chevron-right.svg";
import { Image, Layout, SEOTags } from "../components";
import * as styles from "../styles/portfolio.module.scss";

class Portfolio extends Component {
  renderPaginatedIcons = (icons, className) => {
    return (
      <div className={styles.paginatedIcons}>
        {icons &&
          icons.map((page, i) => {
            return (
              <Row
                key={`row_${i}`}
                xs={2}
                md={2}
                lg={4}
                className={`${styles[className]} ${styles.space} ${
                  i === 0 ? styles.show : ""
                }`}
              >
                {page.map((row) => {
                  return row.map((icon, k) => {
                    return (
                      <Col key={`col_${k}`} md lg className={styles.colHeight}>
                        <div key={`div_${k}`} className={styles.icon}>
                          {icon.name && (
                            <>
                              <Image
                                key={`img_${k}`}
                                src={icon.publicURL}
                                alt={icon.name}
                              />
                              <span
                                key={`span_${i}`}
                                className={styles.iconName}
                              >
                                {icon.name
                                  ? icon.name.split("_").slice(1).join(" ")
                                  : ""}
                              </span>
                            </>
                          )}
                        </div>
                      </Col>
                    );
                  });
                })}
              </Row>
            );
          })}
      </div>
    );
  };

  nextIcon = (event) => {
    let iconsSet = [];
    const buttonSelection = event.target.id.split("_");

    if (buttonSelection[0] === "solution") {
      iconsSet = document.getElementsByClassName(styles.solutionsList);
    } else if (buttonSelection[0] === "industry") {
      iconsSet = document.getElementsByClassName(styles.industriesList);
    } else {
      return;
    }

    iconsSet = Array.from(iconsSet);

    for (let i = 0; i < iconsSet.length; i++) {
      if (iconsSet[i].classList.contains(styles.show)) {
        iconsSet[i].classList.toggle(styles.show);
        if (i === iconsSet.length - 1) {
          iconsSet[0].classList.toggle(styles.show);
        } else {
          iconsSet[i + 1].classList.toggle(styles.show);
        }
        break;
      }
    }
  };

  render() {
    const industryIcons = this.props.data.industryIcons.nodes;
    const solutionIcons = this.props.data.solutionIcons.nodes;

    if (industryIcons.length % 8 !== 0) {
      const col = { name: "", publicUrl: "" };
      for (let i = 0; i < industryIcons.length % 8; i++) {
        industryIcons.push(col);
      }
    }

    if (solutionIcons.length % 8 !== 0) {
      const col = { name: "", publicUrl: "" };
      for (let i = 0; i < solutionIcons.length % 8; i++) {
        solutionIcons.push(col);
      }
    }

    let paginator = [];
    while (industryIcons.length) {
      paginator.push(industryIcons.splice(0, 4));
    }
    while (paginator.length) {
      industryIcons.push(paginator.splice(0, 2));
    }

    paginator = [];
    while (solutionIcons.length) {
      paginator.push(solutionIcons.splice(0, 4));
    }
    while (paginator.length) {
      solutionIcons.push(paginator.splice(0, 2));
    }

    return (
      <Layout layoutClassName="portfolio-page">
        <div className={styles.portfoliopage}>
          <section className={styles.portfolio}>
            {/* <div className={`${styles.heroAnimation} ${styles.computer}`}>
              <lottie-player
                src="/lottie/Home-hero.json"
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
              ></lottie-player>
            </div>
            <div className={`${styles.heroAnimation} ${styles.mobile}`}>
              <lottie-player
                src="/lottie/Home-mobile-hero.json"
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
              ></lottie-player>
            </div> */}
            <Logo className={classNames(styles.watermark, styles.mobile)} />
            <h6 className={styles.header}>PORTFOLIO</h6>
            <h1 className={styles.hero}>
              Over 12 years of software design and build experience
            </h1>
            <p className={styles.details}>
              We build software across all industry verticals delivering fast,
              scalable solutions that place user experience and business value
              in your hands.
            </p>
          </section>
          <section className={styles.industries}>
            <h3>Industries</h3>
            {this.renderPaginatedIcons(industryIcons, "industriesList")}
            <Row>
              <Col className={styles.more}>
                <button
                  id="industry_next"
                  className={styles.buttonMore}
                  onClick={this.nextIcon}
                >
                  MORE
                  <IconChevron />
                </button>
              </Col>
            </Row>
          </section>
          <section className={styles.solutions}>
            <h3>Solutions</h3>
            {this.renderPaginatedIcons(solutionIcons, "solutionsList")}
          </section>
        </div>
      </Layout>
    );
  }
}
export default Portfolio;

export const Head = ({ location }) => (
  <SEOTags title={"Portfolio | Mahusai Global"} pathname={location.pathname} />
);

export const query = graphql`
  query {
    industryIcons: allFile(
      filter: { relativeDirectory: { eq: "assets/icons/industry" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        publicURL
      }
    }
    solutionIcons: allFile(
      filter: { relativeDirectory: { eq: "assets/icons/service" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        publicURL
      }
    }
  }
`;
